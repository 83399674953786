const menus = [
    {
        id: 1,
        tomenu: "#about",
        namemenu: "O ebooku"
    },
    {
        id: 2,
        tomenu: "#chapters",
        namemenu: "Rozdziały"
    },
    {
        id: 3,
        tomenu: "#testimonial",
        namemenu: "Opinie"
    },
    {
        id: 4,
        tomenu: "#author",
        namemenu: "author"
    },
    {
        id: 5,
        tomenu: "#pricing",
        namemenu: "Cennik"
    },
    {
        id: 6,
        tomenu: "#contact",
        namemenu: "Kontakt"
    },
];



export default menus ;