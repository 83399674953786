import Home01 from './Home01';
import Home02 from './Home02';
import Home03 from './Home03';
import Home04 from './Home04';


const routes = [
    { path: "/", component: Home01},
    { path: "/home-02", component: Home02},
    { path: "/home-03", component: Home03},
    { path: "/home-04", component: Home04},
]

export default routes;